// Newsletter sliders
import Swiper from 'swiper';
import SimpleBar from 'simplebar';
// const newsletterLeftSlider = document.querySelector(".swiper-container.newsletter__gifts--left");
// const newsletterRightSlider = document.querySelector(".swiper-container.newsletter__gifts--right");
// if (newsletterLeftSlider) {
//     window.addEventListener("load", function(){
//         newsletterLeftSlider.classList.add("loaded");
//         const newsletterLeftSwiper = new Swiper(newsletterLeftSlider, {
//             loop: true,
//             centeredSlides: true,
//             direction: "vertical",
//             slidesPerView: 2.2,
//             spaceBetween: 50,
//             autoplay: {
//                 delay: 0,
//             },
//             speed: 7500,
//             breakpoints: {
//                 1100: {
//                     slidesPerView: 4.1
//                 },
//                 1200: {
//                     slidesPerView: 3.6
//                 },
//                 1280: {
//                     slidesPerView: 2.9
//                 },
//                 1366: {
//                     slidesPerView: 2.7
//                 },
//             }
//         });
//     });
// }
// if (newsletterRightSlider) {
//     window.addEventListener("load", function(){
//         newsletterRightSlider.classList.add("loaded");
//         const newsletterRightSwiper = new Swiper(newsletterRightSlider, {
//             loop: true,
//             centeredSlides: true,
//             direction: "vertical",
//             slidesPerView: 2.15,
//             spaceBetween: 50,
//             autoplay: {
//                 delay: 0,
//                 reverseDirection: true,
//             },
//             speed: 7500,
//
//         });
//     });
// }
const newsletterGiftPapers = document.querySelector(".newsletter__gift-papers");
const newsletterBtnOpen = document.querySelector(".btn-newsletter.btn-gift");
const newsletterBtnClose = document.querySelector(".newsletter__gifts-close");
if (newsletterGiftPapers && newsletterBtnOpen && newsletterBtnClose) {
    const newsletterGiftContainer = document.querySelector(".newsletter__gift-container");
    const newsletterScrollWrap = document.querySelector(".newsletter__scroll-wrap");
    const newsletterCustomScroll = new SimpleBar(newsletterScrollWrap);
    const newsletterMain = document.querySelector(".newsletter.gifts");
    const newsletterGiftInput = document.getElementById("gift-id");
    const newsletterFormContainer = document.querySelector(".newsletter__container");
    let body = document.querySelector("body");
    const bodyCustomScroll = new SimpleBar(document.querySelector(".body-wrap"));
    let columnCount = newsletterGiftPapers.dataset.column;
    let activeText;
    let selected;
    let nextItem;
    let clone;
    let sizeaAfterResize = 0.45;
    let childrenSelected;
    function setScale(columnCount, sizeaAfterResize = 0.5) {
        let columnWidth = 1 / columnCount;
        let scale =  sizeaAfterResize / columnWidth;
        return scale;
    };
    newsletterBtnOpen.addEventListener("click", function(e){
        e.preventDefault();
        e.stopPropagation();
        newsletterGiftPapers.classList.add("active");
        bodyCustomScroll.getScrollElement().scrollTo(0,0);
        let timer = setTimeout(function(){
            body.classList.add("burger-open");
            newsletterFormContainer.classList.add("gifts-open");
        }, document.body.clientWidth < 1025 ? 0 : 350);
    });
    newsletterBtnClose.addEventListener("click", function(e){
        e.preventDefault();
        e.stopPropagation();
        selected = newsletterGiftPapers.querySelector(".selected");
        childrenSelected = newsletterGiftPapers.querySelector(".children-selected");
        // newsletterGiftContainer.removeChild(clone);
        if (selected) {
            selected.classList.remove("selected");
            selected.parentElement.classList.remove("selected");
            body.classList.add("burger-open");
            selected.style = "";
        }
        else {
            newsletterGiftPapers.classList.remove("active");
            body.classList.remove("burger-open");
            newsletterFormContainer.classList.remove("gifts-open");
            if (selected) {
                selected.classList.remove("selected");
                selected.parentElement.classList.remove("selected");
                selected.style = "";
            }
        }
        if (childrenSelected) {
            childrenSelected.classList.remove("children-selected");
        }
        newsletterFormContainer.classList.remove("gift-selected");
        newsletterGiftPapers.classList.remove("select-active");
        newsletterGiftPapers.classList.remove("hide-other");
        body.classList.remove("gift-selected");
        newsletterGiftInput.value = "";
    });
    newsletterGiftPapers.addEventListener("click", function(e){
        const self = this;
        if (e.target.dataset.giftId && !e.target.classList.contains("selected")) {
            let left = e.target.offsetLeft;
            let top = e.target.offsetTop;
            activeText = e.target.querySelector(".newsletter__gift-text");
            newsletterCustomScroll.getScrollElement().scrollTo(0,0);
            selected = e.target;
            e.target.classList.add("selected");
            e.target.parentElement.classList.add("children-selected");
            let textScale = 1 / setScale(columnCount, sizeaAfterResize);
            // activeText.style.transform = "scale(" + textScale + ")";
            e.target.style.transform = "translate3d(-"+ left + "px" + ", -" + top + "px" + ", 0) scale(" + setScale(columnCount, sizeaAfterResize) + ")";
            this.classList.add("hide-other");
            body.classList.add("gift-selected");
            newsletterGiftInput.value = e.target.dataset.giftId;
            let timer = setTimeout(function(){
                self.classList.add("select-active");
                body.classList.remove("burger-open");
                newsletterFormContainer.classList.add("gift-selected");
            }, document.body.clientWidth < 1025 ? 0 : 350);
        }
        else if (e.target.dataset.giftId && e.target.classList.contains("selected")) {
            this.classList.remove("select-active");
            this.classList.remove("hide-other");
            body.classList.remove("gift-selected");
            e.target.classList.remove("selected");
            e.target.parentElement.classList.remove("children-selected");
            e.target.style = "";
            activeText.style = "";
            bodyCustomScroll.getScrollElement().scrollTo(0,0);
            body.classList.add("burger-open");
            newsletterFormContainer.classList.remove("gift-selected");
            newsletterGiftInput.value = "";
        }
    });
}

document.addEventListener('DOMContentLoaded',function() {
    var swiper = new Swiper(".monthly-products-swiper", {
        slidesPerView: 'auto',
        spaceBetween: 30,
    });
    var swiperVideo = new Swiper(".video-swiper", {
        slidesPerView: 'auto',
        spaceBetween: 30,
        slidesOffsetAfter: 15,
        slidesOffsetBefore: 15,
        navigation: {
            nextEl: '.new-video__slider-next',
            prevEl: '.new-video__slider-prev',
        },
        breakpoints: {
            480: {
                slidesOffsetAfter: 105,
                slidesOffsetBefore: 105,
            }
          }
    });

    const showMore = document.getElementsByClassName('products__show-more')[0];
    const hiddenWrapper = document.getElementsByClassName('hidden-wrapper')[0];

    if(showMore) {
        showMore.addEventListener('click',function(){
            if(hiddenWrapper.classList.contains('show')) {
                hiddenWrapper.classList.remove('show');
                this.innerHTML = `Pokaż więcej (${this.dataset.numberToShow})`;
            } else {
                hiddenWrapper.classList.add('show');
                this.innerHTML = `Pokaż mniej (${this.dataset.numberToShow})`;
            }
        })
    }

    const menuBurger = document.getElementsByClassName('menu-toggle')[0];
    const bodyWrapper = document.getElementsByClassName('wrapper')[0];

    if(menuBurger) {
        menuBurger.addEventListener('click', () => {
            if(bodyWrapper) {
                if(bodyWrapper.classList.contains('overflow')) {
                    setTimeout(() => {
                        bodyWrapper.classList.remove('overflow');
                    }, 350);
                } else {
                    bodyWrapper.classList.add('overflow');
                }
            }
        })
    }

    const links = document.querySelectorAll('.products__items-item-details');
    const sections = document.querySelectorAll('.single-product__selected-item');

    if(links.length > 0) {
        for(let i = 0; i < links.length; i++) {
            links[i].addEventListener('click', function(event){
                for(let j =0; j < sections.length; j++) {
                    if(sections[j].classList.contains(this.dataset.active)) {
                        window.scroll({
                            top : sections[j].getBoundingClientRect().top + window.scrollY - 80,
                            left : 0,
                            behavior : 'smooth'
                        });
                    }
                }
            })
        }
    }

    const sliderHpTop = document.querySelector(".swiper-hp-top");
    if (sliderHpTop) {
        const swiperHpTop = new Swiper(sliderHpTop, {
            slidesPerView: 1,
            loop: true,
            autoHeight: true,
            navigation: {
                nextEl: '.swiper-hp-top .swiper-button-next',
                prevEl: '.swiper-hp-top .swiper-button-prev',
            },
            autoplay: {
                delay: 3000,
            }
        });
        console.log(swiperHpTop);
    }

    const zaiksSlider = document.querySelector(".zaiks-swiper");
    if (zaiksSlider) new Swiper(zaiksSlider, {
        slidesPerView: 0.8,
        spaceBetween: 0.5,
        breakpoints: {
            575: {
                slidesPerView: 1,
                spaceBetween: 0.8,
            },
            767: {
                slidesPerView: 1.5,
                spaceBetween: 1
            }
        },
        centeredSlides: true,
        roundLengths: true,
        loop: true,
        loopAdditionalSlides: 30,
        effect: 'coverflow',
        coverflowEffect: {
            rotate: 0,
            slideShadows: false,
            depth: 300,
            stretch: -200,
        },
        pagination: {
            el: '.swiper-slide__pagination',
            type: 'bullets',
        },
        navigation: {
            nextEl: '.zaiks-swiper .swiper-button-next',
            prevEl: '.zaiks-swiper .swiper-button-prev',
        },
        autoplay: {
            delay: 3000,
        }
    });

    // Strefa ZAiKS nav

    const zaiksMenu = document.querySelector('.zaiks-list__menu-list');
    const zaiksMenuBtn = document.querySelector('.zaiks-list__menu-btn');

    if (zaiksMenu && zaiksMenuBtn) {
        zaiksMenuBtn.addEventListener('click', function() {
            this.classList.toggle("open");
            zaiksMenu.classList.toggle("open");
        });
    }
})
